<template>
    <v-img
        :src="productImageUrl"
        v-bind="$attrs"
    ></v-img>
</template>

<script lang="ts" setup>
    import { useFullImage } from '~/composables';
    import type { FullImage } from '~/types';

    const props = defineProps<{
        imageList: Nilish<FullImage[]>;
    }>();
       
    const { getSingleImageUrlFromFullImage, fallbackImage } = useFullImage();

    const productImageUrl = computed<string | undefined>(() => {
        if (!props.imageList?.length) {
            return fallbackImage.value;
        }

        return getSingleImageUrlFromFullImage(props.imageList[0], 'x-small') ?? fallbackImage.value;
    });
</script>

<style lang="scss"></style>
